<template>
    <div class="container">
        <mescroll-vue class="mescroll" id="container" ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
            <div class="mescroll-view">
                <div class="mescroll-item" v-for="(item, index) in dataList" :key="index">
                    <div class="text">
                        <div class="title">{{item.content}}</div>
                        <div class="number">{{item.money}}</div>
                    </div>
                    <div class="text2">
                        <div class="date">{{item.create_time}}</div>
                        <div class="label">数量</div>
                    </div>
                </div>
            </div>
        </mescroll-vue>
    </div>
</template>
  
<script>
import MescrollVue from "mescroll.js/mescroll.vue";
import { getFinanceList } from "@/request/api"
export default {
    components: { MescrollVue },
    data() {
        return {
            mescroll: null,
            mescrollDown: {},
            mescrollUp: {
                callback: this.upCallback,
                empty: {
                    tip: "No Data",
                    warpId: "container",
                    icon: "/images/mescroll-empty.png",
                },
            },
            dataList: [], // 列表数据
            number: "",
        };
    },
    mounted() { },
    methods: {
        mescrollInit(mescroll) {
            this.mescroll = mescroll;
        },
        upCallback(page, mescroll) {
            getFinanceList({ page: page.num, limit: page.size, type: 1 }).then((res) => {
                if (res.code == 200) {
                    // 请求的列表数据
                    let arr = res.data.list;
                    // 如果是第一页需手动置空列表
                    if (page.num === 1) this.dataList = [];
                    // 把请求到的数据添加到列表
                    this.dataList = this.dataList.concat(arr);
                    // 数据渲染成功后,隐藏下拉刷新的状态
                    this.$nextTick(() => {
                        mescroll.endBySize(arr.length, res.data.total);
                    });
                } else {
                    this.$toast.fail(res.msg);
                    mescroll.endErr();
                }
            });
        },
        routerPush(path) {
            this.$router.push({ path });
        },
    },
};
</script>
<style lang="scss" scoped>
.container {
    overflow: hidden;
    background-color: #fff !important;

    .mescroll {
        .mescroll-view {

            .mescroll-item {
                padding: 26px 34px;
                margin-bottom: 30px;
                background-color: #F4FFF7;
                border-radius: 20px;

                .text,
                .text2 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .text {
                    font-size: 32px;
                    font-weight: 500;
                    color: #333333;
                    margin-bottom: 25px;
                }

                .text2 {
                    font-size: 28px;
                    font-weight: 500;
                    color: #999999;
                }
            }
        }
    }
}
</style>